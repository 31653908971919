import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import '@mui/x-data-grid/themeAugmentation';
import { gridClasses } from '@mui/x-data-grid/constants/gridClasses';

export const MuiDataGrid: Components<Theme>['MuiDataGrid'] = {
  defaultProps: {
    rowHeight: 40,
    columnHeaderHeight: 37,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      border: 'none',
      boxShadow: 'none',
      '& .Mui-selected': {
        backgroundColor: theme.palette.grey[50],
      },
      '& .MuiDataGrid-row:hover': {
        backgroundColor: theme.palette.grey[50],
      },
    }),
    columnHeaders: ({ theme }) => ({
      backgroundColor: 'none',
      color: theme.palette.grey[700],
    }),
    columnHeader: ({ theme }) => ({
      borderRight: '1px solid',
      borderColor: theme.palette.secondary.dark,
      '&:last-of-type': {
        borderRight: 0,
      },
      '& .MuiDataGrid-sortIcon': {
        marginRight: '12px',
      },
      ':focus': {
        outline: 'none',
      },
      ':focus-within': {
        outline: 'none',
      },
      [`&:last-of-type .${gridClasses.columnHeader}`]: {
        borderRight: 1,
      },
    }),
    columnHeaderTitle: ({ theme }) => ({
      ...theme.typography.body1,
      fontWeight: 'bold',
    }),
    cellContent: ({ theme }) => ({
      ...theme.typography.subtitle2,
    }),
    cell: () => ({
      ':focus': {
        outline: 'none',
      },
      ':focus-within': {
        outline: 'none',
      },
    }),
    filterFormValueInput: {
      '& .MuiTextField-root': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    panelWrapper: () => ({
      padding: '10px',
    }),
    row: {
      [`&:last-of-type .${gridClasses.cell}`]: {
        borderBottom: 0,
      },
    },
    sortIcon: {
      marginRight: '10px',
    },
    withBorderColor: ({ theme }) => ({
      borderColor: theme.palette.grey[200],
    }),
  },
};
