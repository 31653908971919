import type { SWRMutationConfiguration } from 'swr/mutation';
import { AxiosError } from 'axios';
import { swrMutationOptions } from '@config/swrMutationOptions';
import { APIError, PlanSearchResponse } from '@apps/Plans/modules/PlanSearch/types/api';

type Data = object;

export interface OnSuccess {
  (
    data: Data,
    key: string,
    config: SWRMutationConfiguration<Data, Error>,
  ): void | undefined | Promise<void>;
}

export interface OnError {
  (
    err: Error | AxiosError | APIError,
    key: string,
    config: SWRMutationConfiguration<Data, Error>,
  ): void | undefined;
}

export interface OptimisticData {
  (arg0: Data): Data;
}

export interface MutationOptions {
  onSuccess?: OnSuccess;
  onError?: OnError;
  optimisticData?: object;
  populateCache?: boolean;
  revalidate?: boolean;
  rollbackOnError?: boolean;
  throwOnError?: boolean | undefined;
}

export const getMutationOptions = (mutationOptions: MutationOptions = {}) => ({
  ...swrMutationOptions,
  throwOnError:
    typeof mutationOptions.throwOnError !== 'undefined'
      ? mutationOptions.throwOnError
      : typeof mutationOptions.onError !== 'function',
  ...mutationOptions,
  optimisticData: mutationOptions.optimisticData as PlanSearchResponse | undefined,
});
